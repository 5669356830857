<template>
  <div>
    <div ref="barchart" :style="echartStyle" class="lineechart"></div>
  </div>
</template>
<script >
let echarts = require('echarts')
// import * as echarts from 'echarts/lib/echarts'
// let echarts = require('echarts/lib/echarts')
// require('echarts/lib/chart/bar')
// // 引入提示框和title组件
// require('echarts/lib/component/tooltip')
// require('echarts/lib/component/title')
export default {
  name: 'Bar',
  props: {
    echartStyle: {  // 样式 - 1
      type: Object,
      default() {
        return {
          width: "99%"
        }

      }
    },
    titleText: {   //标题文本 - 1
      type: String,
      default: ''
    },

    opinion: {    //区域名称 - 1
      type: Array,
      default() {
        return []
      }
    },

    opinionData: {   // 区域数据 - 1
      type: Array,
      default() {
        return []
      }
    },

    x: {  // x 轴
      type: Array,
      default() {
        return []
      }
    },
    multipleSelection: {//legend 选中值
      type: Array,
      default:() => []
    }
  },
  data() {
    return {
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.drawLine();
    })
  },
  watch: {
    multipleSelection() {
      this.drawLine()
    },
    x() {
      this.drawLine()
    }
  },
  methods: {

    drawLine() {
      var myCharts;
      if (myCharts != null && myCharts != "" && myCharts != undefined) {
        myCharts.dispose();
      }
      myCharts = echarts.init(this.$refs.barchart)
      let that = this;

      let option = {
        title: {
          left: 'center',
          text: this.titleText, //标题文本
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          icon: 'circle',
          left: '2%',
          data: this.opinion,// 区域名称


        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {
              show: false,//控制保存按钮显示隐藏
            }
          }
        },
        xAxis: {   // x 轴
          type: 'category',
          boundaryGap: false,
          data: this.x
        },
        yAxis: {   // y 轴
          type: 'value',
        },
        series: this.opinionData  // 区域数据
      }
      // myCharts.setOption(option)
      window.addEventListener('resize', this.windowResizeFn)
      myCharts.on('legendselectchanged', function (params) {
        that.$emit("legendselect", params.selected)
        // 
      });

      let obj = {};
      for (let j = 0; j < this.opinion.length; j++) {
        for (let i = 0; i < this.multipleSelection.length; i++) {
          if (this.opinion[j] == this.multipleSelection[i]) {
            obj[this.multipleSelection[i]] = true;
          }
          if (!obj[this.opinion[j]]) {
            obj[this.opinion[j]] = false;
          }

        }
      }

      option.legend.selected = obj;
      myCharts.setOption(option, true)
    },
    windowResizeFn(myCharts) {
      myCharts.resize();
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.windowResizeFn);
  },
}

</script>
<style lang='scss' scoped>
.lineechart {
  // margin-left: 19px;
  width: 99%;
}
</style>
