<template>
  <div class="Analyzecharts">
    <div class="head">
      <div>
        <h1>{{ h1 }}</h1>
        <p v-if="echartstartdate && echartenddate">
          {{ $t("label.chatter.dateRange") }}:
          {{ $t("label.appointment.wizard.subtitle2-1") }}
          {{ echartstartdate }} {{ $t("label.chatter.to") }} {{ echartenddate }}
        </p>
      </div>
      <div class="save">
        <!-- <p>Save to dashboard</p> -->
      </div>
    </div>
    <Analyzecharts
      :echartStyle="style"
      :titleText="title"
      :opinion="barName"
      :opinionData="info"
      :x="barX"
      :multipleSelection="multipleSelection"
    ></Analyzecharts>
    <div class="foot">
      {{ echarttitle }}
    </div>
  </div>
</template>
<script >
import Analyzecharts from "./Analyzeecharts"
import * as marketChannel from "../api";//api
export default {
  components: {
    Analyzecharts
  },
  props: {
    h1: {   //标题
      type: String,
      default: ''
    },
    echarttitle: {   //底标
      type: String,
      default: ''
    },
    echartstartdate: {   //开始时间
      type: String,
      default: ''
    },
    echartenddate: {   //结束时间
      type: String,
      default: ''
    },
    timeHorizon: {//时间间隔
      type: String,
      default: ''
    }
  },
  data() {
    return {
      style: {
        height: '400px',
        width: "99%"
      },
      barName: [this.$i18n.t('label.views'), this.$i18n.t('label.submissions')],
      title: '',
      barX: [],

      info: [
        {
          name: this.$i18n.t('label.views'),
          type: 'line',
          itemStyle: { normal: { lineStyle: { color: '#E5851A' }, color: '#E5851A' }, },
          data: []
        },
        {
          name: this.$i18n.t('label.submissions'),
          type: 'line',
          itemStyle: { normal: { lineStyle: { color: '#51D3D9' }, color: '#51D3D9' }, },
          data: []
        }
      ],
      multipleSelection: [this.$i18n.t('label.views'), this.$i18n.t('label.submissions')],

    }
  },
  methods: {
    getecharts() {
      let obj = {
        id: this.$store.state.creatform.itemDataId,
        startTime: this.echartstartdate,
        endTime: this.echartenddate,
        timeHorizon: this.timeHorizon,
      }
      marketChannel.getfromData(obj).then((res) => {
        if (res.returnCode == "1") {
          this.barX = res.data.timeList;
          let data1 = res.data.timeList;
          let viewsdata = [];
          viewsdata.length = data1.length;
          let Submissionsdata = [];
          let data2 = res.data.submissions;
          for (var i = 0; i < data1.length; i++) {
            viewsdata[i] = 0;
            Submissionsdata[i] = 0;
            if (data2.length > 0) {
              for (var j = 0; j < data2.length; j++) {
                if (data1[i] == data2[j].date) {
                  viewsdata[i] = data2[j].views;
                  Submissionsdata[i] = data2[j].submissions;
                }
              }
            }
          }
          this.info[0].data = viewsdata;
          this.info[1].data = Submissionsdata;

        }
      })
    },
  },
  created() {
    this.times = [this.echartstartdate, this.echartenddate];
    this.getecharts();
  },
  computed: {
    times() {
      const { echartstartdate, echartenddate } = this
      return {
        echartstartdate,
        echartenddate
      }
    }
  },
  watch: {
    times: {
      handler: function () {
        this.getecharts();
        // this.getYearAndMonthAndDay(this.echartstartdate, this.echartenddate);
      },
      deep: true
    }
  }
}

</script>
<style lang='scss' scoped>
.Analyzecharts {
  width: 100%;
  height: 530px;
  background: #ffffff;
  margin-top: 12px;
  .head {
    width: 99%;
    margin-left: 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    font-family: MicrosoftYaHei-Bold;
    letter-spacing: 0;
    h1 {
      font-size: 16px;
      color: #333333;
      font-weight: bolder;
    }
    p {
      font-size: 12px;
      color: #333333;
    }
    .save {
      text-align: right;
      margin-right: 37px;
      p {
        font-family: MicrosoftYaHei-Bold;
        font-size: 14px;
        color: #2D6CFC;
        letter-spacing: 0;
        font-weight: bolder;
      }
    }
  }
  .foot {
    width: 99%;
    height: 50px;
    text-align: center;
    font-size: 14px;
    color: #333333;
    font-weight: bolder;
    line-height: 50px;
  }
}
</style>
