<!--  -->
<template>
  <div class="top">
    <div class="h1Title">{{ title }}</div>
    <div class="dateTitle" v-if="startdate && enddate">
      {{ $t("label.chatter.dateRange") }}:
      {{ $t("label.appointment.wizard.subtitle2-1") }} {{ startdate }}
      {{ $t("component.chatter.label.to") }} {{ enddate }}
    </div>
    <tablePanel
      ref="tablePanel"
      :tit="title"
      :customHeader="true"
      :dataList="dataList"
      :tableAttr="tableAttr"
    ></tablePanel>
  </div>
</template>

<script>
import tablePanel from '@/views/marketForm/components/TablePanel/index.vue'
export default {
  props: {
    title: {   //formid 
      type: String,
      default: 'Conversion pages'
    },
    startdate: {
      type: String,
      default: ''
    },
    enddate: {
      type: String,
      default: ''
    },
    dataList: {
      type: Array,
      default: ()=>[]
    },
    tableAttr: {
      type: Array,
      default: ()=>[]
    },
  },
  data() {
    return {
      // tableAttr: [  //schemefieldType  类型
      //   {
      //     "schemefieldName": "visiturl", "nameLabel": "visiturl", "schemefieldType": "L", "sortDir": "asc", "locked": false, "isText": true

      //   },
      //   {
      //     "schemefieldName": "submissions", "nameLabel": "submissions", "schemefieldType": "L", "sortDir": "asc", "locked": false, "isText": true

      //   },
      //   {
      //     "schemefieldName": "views", "nameLabel": "views", "schemefieldType": "L", "sortDir": "asc", "locked": false, "isText": true

      //   },
      // {
      //   "length": "1024", "label": "name", "type": "L", "id": "ffe2011000048322f1Bj", "schemefieldName": "name", "nameLabel": "类型", "schemefieldType": "L", "schemefieldLength": "1024", "colwidth": null,
      //   "sortByThis": true, "sortDir": "asc", "locked": false, "isText": true
      // },
      // {
      //   "length": "1024", "label": "age", "type": "S", "id": "ffe2011000048322f1Bj", "schemefieldName": "age", "nameLabel": "类型", "schemefieldType": "S", "schemefieldLength": "1024", "colwidth": null,
      //   "sortByThis": true, "sortDir": "asc", "locked": false, "isText": true
      // },
      // {
      //   "length": "1024", "label": "sex", "type": "L", "id": "ffe2011000048322f1Bj", "schemefieldName": "sex", "nameLabel": "类型", "schemefieldType": "L", "schemefieldLength": "1024", "colwidth": null,
      //   "sortByThis": true, "sortDir": "asc", "locked": false, "isText": true
      // }
      //   ],
      // dataList: [{  "visiturl": "广告宣传", "submissions": "123", "views": "23"},
      //  { "visiturl": "广告宣传", "submissions": "123", "views": "23"}],
    };
  },
  components: {
    tablePanel,
  },
  //方法集合
  methods: {
  },
  created() {
  }
}
</script>
<style lang='scss' scoped>
.top {
  background-color: #ffffff;
}
.h1Title {
  padding-top: 20px;
  height: 30px;
  margin: 10px 0px 20px 20px;
  font-family: MicrosoftYaHei-Bold;
  font-size: 16px;
  color: #080707;
  letter-spacing: 0;
  font-weight: bold;
}
.dateTitle {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #333333;
  letter-spacing: 0;
  height: 20px;
  margin: 20px 0px 20px 20px;
}
</style>