<template>
  <div class="Analyzedate">
    <div class="title">
      <h1>{{ $t("label.aggregated_formsdata") }}</h1>
      <p v-if="analyzedate.startdate && analyzedate.enddate">
        {{ $t("label.chatter.dateRange") }}:
        {{ $t("label.appointment.wizard.subtitle2-1") }}
        {{ analyzedate.startdate }} {{ $t("label.chatter.to") }}
        {{ analyzedate.enddate }}
      </p>
    </div>
    <div :class="analyzedate.metrics.length > 5 ? 'conen' : 'content'">
      <dl
        v-for="(val, index) in analyzedate.metrics"
        :key="index"
        @click="tableform"
      >
        <dt>{{ val.dt }}</dt>
        <dd>{{ val.dd }}</dd>
      </dl>
    </div>
    <Analydialog
      ref="analydia"
      :formshow="formshow"
      @func="func"
      :enddate="analyzedate.enddate"
      :startdate="analyzedate.startdate"
    ></Analydialog>
  </div>
</template>
<script >
import Analydialog from '@/views/marketForm/components/Analydialog.vue'
export default {
  props: {
    analyzedate: {
      type: Object,
      default: ()=>{}
    },
  },
  components: {
    Analydialog
  },
  data() {
    return {
      formshow: false,
    }
  },
  methods: {
    tableform() {
      this.formshow = true;
      this.$refs.analydia.getformdata();


    },
    func(show) {
      this.formshow = show
    },
  }
}
</script>
<style lang='scss'  scoped>
.Analyzedate {
  width: 100%;
  min-height: 273px;
  background: #ffffff;
  .title {
    width: 100%;
    overflow: hidden;
    h1 {
      font-family: MicrosoftYaHei-Bold;
      font-size: 16px;
      color: #080707;
      font-weight: bolder;
      margin-left: 21px;
      margin-top: 20px;
    }
    p {
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #333333;
      margin-left: 21px;
      margin-top: 10px;
    }
  }
  .conen {
    width: 100%;
    dl {
      width: 20%;
      float: left;
      margin-top: 20px;
      cursor: pointer;
      dt {
        font-family: MicrosoftYaHei-Bold;
        font-size: 12px;
        color: #888888;
        letter-spacing: 0;
        text-align: center;
        width: 100%;
      }
      dd {
        font-size: 24px;
        color: #2D6CFC;
        text-align: center;
        width: 100%;
        margin: 0;
        margin-top: 6px;
      }
    }
  }
  .content {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    dl {
      width: 20%;
      margin-top: 60px;
      cursor: pointer;
      dt {
        font-family: MicrosoftYaHei-Bold;
        font-size: 12px;
        color: #888888;
        letter-spacing: 0;
        text-align: center;
        width: 100%;
      }
      dd {
        font-size: 24px;
        color: #2D6CFC;
        text-align: center;
        width: 100%;
        margin: 0;
        margin-top: 6px;
      }
    }
  }
}
</style>
